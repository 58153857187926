import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  //props: ['options'],
  data(){
    return{      
        options: {
          scales: {
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
                reverse:false
              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              display:false,
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          tooltips: {
            enabled: true,
            titleColor: '#000',
            bodyColor: 'black',
            footerColor: '#000',
            // backgroundColor: '#fff',
            displayColors: false,
            cornerRadius: 2,
            mode: 'single',
            callbacks: {
              label: function(tooltipItems) {
                return '' + tooltipItems.yLabel;
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          height: 200
        }
      
    }
  },  
  
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    
    this.renderChart(this.chartData, this.options)
  }
}