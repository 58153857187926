<template>
    <line-chart :chart-data="datacollection" v-if="datacollection" :height="height"></line-chart>
</template>

<script>
  import LineChart from '../assets/js/LineChart.js'
  import {mapActions,mapGetters} from 'vuex'
  export default {
    props: [ 'height', 'colorClass', 'tension', 'noBorder', 'data', 'pointRadius', 'hitRadius', 'fill', 'pointBackgroundColor', 'borderColor' ],
    components: {
      LineChart
    },
    data() {
      return {
        datacollection: {},  
        color: {
          bg: 'hsl(87deg 50% 62%)',
          border: '#fff'
        }   
      }
    },
    mounted () {
      this.setColor(),
      this.fillData()
    },
    computed:{
      ...mapGetters(['getContacts','getGraf'])
    },
    methods: {
      ...mapActions(["contactsAction"]),
      fillData () {
        if(this.data.values[0] && typeof this.data.values[0] == 'object') {
          this.datacollection = {
            labels: this.data?.labels || ["01/01", "02/01", "03/01", "04/01", "05/01", "06/01", "07/01"],
            datasets: this.data.values
          }
          this.datacollection.datasets = this.datacollection.datasets.map(el => {
            if(!el.backgroundColor)
              el.backgroundColor = this.color.bg
              if(!el.borderColor)
                el.borderColor = this.borderColor || this.color.border
              if(!el.pointBackgroundColor)
                el.pointBackgroundColor = this.pointBackgroundColor || this.color.border
            return el
          })
        } else {
          this.datacollection = {
            labels: this.data?.labels || ["01/01", "02/01", "03/01", "04/01", "05/01", "06/01", "07/01"],
            datasets: [
              {
                pointBackgroundColor: this.pointBackgroundColor || this.color.border,
                pointRadius: this.pointRadius || 0,
                hitRadius: this.hitRadius || 30,
                backgroundColor: this.color.bg,
                label: 'My First Dataset',
                data: this.data?.values || [1, 15, 13, 10, 11, 15, 10],
                fill: this.fill || true,
                borderColor: this.color.border,
                tension: this.tension || 0.1,
              }
            ]
          }
        }
      },
      setColor() {
        switch(this.colorClass) {
          case 'light-purple':
            this.color.bg = 'hsl(244deg 62% 95%)'
            this.color.border = 'hsl(245deg 36% 58%)'
            break
          case 'purple':
            this.color.bg = 'hsl(242deg 21% 44%)'
            this.color.border = 'hsl(242deg 21% 34%)'
            break
          case 'pink':
            this.color.bg = 'hsl(285deg 61% 70%)'
            this.color.border = 'hsl(285deg 61% 65%)'
            break
          case 'yellow':
            this.color.bg = 'hsl(42deg 100% 50%)'
            this.color.border = 'hsl(42deg 100% 40%)'
            break
          case 'white':
            this.color.bg = "#fff"
            this.color.border = '#000'
            break
          default:
            break
        }
        if(this.noBorder) {
          this.color.border = 'transparent'
        }
      }
    }
  }
</script>

<style scoped>
  .small {
    width: 100%;
    /* max-width: 600px; */
    margin:  auto;
    height: 350px;
  }
</style>